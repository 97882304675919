.multi-step-form {
  margin-top: 50px;
}

.steps-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  flex-wrap: wrap;
  gap: 10px;
}

.step-content-container {
  display: flex;
  gap: 20px;
}

.step-other {
  flex: 1;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.step::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background-color: #00BFFF;
  z-index: -1;
}

.step.step-with-svg::after {
  background-color: #2E8B57;
}

.step.stepOne::after {
  left: 50%;
  width: calc(90% - 20px);
}

.step.stepTwo::after {
  left: 80%;
  width: 70%;
}


.step:last-child::after {
  content: none;
}

.step-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #00BFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #000000;
  font-weight: bold;
  z-index: 2;
  flex-shrink: 0;
}

.step-title {
  margin-left: 10px;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.step.stepTwo {
  justify-content: center;
}

.step.stepThree {
  justify-content: end;
}

.step-circle.completed {
  background-color: #2E8B57;
  color: white;
  border-color: #2E8B57;
}

/* .step-line {
    position: absolute;
    top: 50%; 
    left: calc(50% + 20px); 
    right: 0; 
    height: 2px;
    background-color: #00BFFF;
    z-index: 1;
    transform: translateY(-50%); 
  }
  
  .step-line.line-active {
    background-color: #2E8B57;
  } */

.step:last-child .step-line {
  display: none;
}

.step-content {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.next-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 12px;
}

.prev-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.next-button .btn {
  padding: 10px 35px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
}

.link-previous {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  gap: 6px;
  text-underline-offset: 3px;
}

.link-previous:hover {
  color: #00BFFF;
  text-decoration: none;
}

.link-previous:hover svg g {
  fill: #00BFFF;
  transition: color 0.3s ease;
}

.link-previous .icon-left {
  margin-right: 8px;
  font-size: 18px;
}

.icon-left::before {
  content: '←';
  display: inline-block;
}

.navigation-buttons .btn.previous {
  background-color: #2E8B57;
  color: white;
}

.navigation-buttons .btn.next {
  background-color: #2E8B57;
  ;
  color: white;
}

@media(max-width: 1399px) {
  .step.stepTwo::after {
    left: 80%;
    width: 60%;
  }

  .step.stepOne::after {
    left: 53%;
    width: calc(80% - 20px);
  }
}

@media(max-width: 1199px) {
  .step-line {
    position: absolute;
    top: 50%;
    left: calc(50% + 50px);
    right: 0;
    height: 2px;
    background-color: #00BFFF;
    z-index: 1;
    transform: translateY(-50%);
  }

  .step-title {
    margin-left: 10px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }

  .step.stepOne::after {
    left: 60%;
    width: calc(70% - 20px);
  }

  .step.stepTwo::after {
    left: 85%;
    width: 50%;
  }
}

@media(max-width: 991px) {
  .step-other {
    max-width: 440px;
  }

  .step.stepOne::after {
    display: none;
  }

  .step.stepTwo::after {
    display: none;
  }

  .step.stepTwo {
    justify-content: start;
  }

  .step.stepThree {
    justify-content: start;
  }

  .step-circle {
    width: 35px;
    height: 35px;
  }

  .step-circle.completed svg {
    width: 16px;
  }
}

@media(max-width: 767px) {
  .step-content-container {
    display: flex;
    gap: 10px;
    flex-direction: column-reverse;
  }

  .step-other {
    max-width: unset;
  }

  .step-line {
    display: none;
  }

  .step-circle {
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 480px) {
  .steps-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    flex-wrap: wrap;
    gap: 5px;
  }

  .step-circle {
    width: 30px;
    height: 30px;
  }

  .step-title {
    margin-left: 4px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
}