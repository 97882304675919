.Feedback-container h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 0 !important;
}
.Feedback-container{
    margin-top: 80px;
}
.feedback-icons-content span{
    font-size: 18px;
    font-weight: 600;
}

.feedback-icons-content p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}
.button-containerfeed {
    display: flex;
    justify-content: center;
    
  }

@media (max-width : 991px){
    .feedback-icons-li .col-md-4 {
        flex: 0 0 auto;
        width: 20.333333% !important;
    }
    .Feedback-container {
        margin-top: 55px;
    }
}
@media (max-width : 767px){
    .feedback-icons-li .col-md-4 {
        flex: 0 0 auto;
        width: 27.333333% !important;
    }
}
/* for mobile screens */
@media (max-width: 576px) {
    .Feedback-container h2{
        font-size: 22px;
        font-weight: 400;
        line-height: 1.4;
    }
    .feedback-icons-content {
        margin: -5px 0 0;
    }
    .Feedback-container .feedback-icons-li img {
        max-height: 90px;
        width: auto;
    }
    .feedback-icons-content span {
        font-size: 16px;
        font-weight: 600;
    }
    .feedback-icons-content p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
    .feedback-icons-li .col-md-4 {
        flex: 0 0 auto;
        width: auto !important;
    }
    .Feedback-container {
        margin-top: 35px;
    }
}