.Cribes-container {
    padding: 40px;
}

.Cribes-container h2 {
    font-size: 28px;
    font-weight: 400;
    color: black;
    margin: 0 0 12px;
}
.Cribes-container h3 {
    font-size: 18px;
    font-weight: 400;
    color: black;
    margin: 0 0 12px;
}
.item-cribe {
    margin: 0 0 20px;
}
.Rooms-cribes {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
}
.Rooms-cribes li{
    margin: 0;
    padding: 0 15px;
    list-style: none;
}
.img-fluid {
    max-width: 100%;
}

.Item-badge {
  margin-bottom: 16px;
  position: relative;
}

.Rooms-content h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px;
    color: black;
}

.Rooms-content p {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 10px;
    color: black;
}

.Rooms-content span {
    font-size: 14px;
    font-weight: 600;
}
.Rooms-content span span.price_loyer {
    font-size: 24px;
    line-height: 30px;
}
.Item-badge {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
}

.notify-badge {
    position: absolute;
    left: 11px;
    top: 11px;
    text-align: center;
    border-radius: 6px;
    color: white;
    padding: 5px 5px;
    z-index: 1;
    font-size: 14px;
    font-weight: 400;
    background: rgba(53, 206, 146, 1) !important;
}
.promo-image {
    position: absolute;
    right: 11px; 
    top: 0;
    border-radius: 3px 3px 0 0;
    text-align: center;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    z-index: 1;
}
/* Ajoutez ce style à votre fichier Cribes.css */

@media (max-width: 767px) {
    .Cribes-container .Maps {
      width: 100%;
      order: 1; /* Change l'ordre des colonnes pour déplacer la carte en haut */
    }
  
    .Cribes-container .maps-block {
      height: 100vh; /* Utilise toute la hauteur de l'écran */
    }
  }
  
.h5{
    font-size:18px;
}
.content-page {
    position: relative;
}
.maps-block iframe {
    width: 100%;
    height: 650px;
}
.maps-block {
    position: relative;
    right: 0;
    top: 0;
    width: calc(100% + 40px);
}
.crib-container {
    width: 33.33%; /* Une largeur d'environ 33% pour chaque conteneur */
    display: inline-block; /* Aligner horizontalement */
    box-sizing: border-box; /* Inclure la bordure et le rembourrage dans la largeur */
    padding: 10px; /* Ajustez le rembourrage selon vos préférences */
    border: 1px solid #ccc; /* Ajoutez une bordure pour séparer les cribs */
  }
  .No-rooms-container{
    padding: 80px 0;
}

.No-rooms-content{
    justify-content: space-between;
    margin-left: 0px;
}

.left {
    text-align: center;
    flex-direction: column;
    gap: 10px;
    margin: 7px;
}

.left h3{
    font-size: 28px;
    font-weight: 400;
}

.left span {
    font-size: 16px;
    font-weight: 400;
}

.ImageNoRooms{
    width: 12%;
    margin: 0 auto;
}

.left button {
    font-size: 16px;
    font-weight: 600;
    padding: 9px;
    background-color: #35CE92;
    outline: none;
    border: none;
    width: 30%;
    border-radius: 8px;
    color: white;
    margin: 0 auto;
    width: 42.33%;
    display: inline-block; 
    box-sizing: border-box;
    padding: 10px; 
    border: 1px solid #ccc;
}
.marker-label {
    background: #000;
    display: block;
    width: 44px;
    height: 45px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    cursor: pointer;
    top:-45px;
    left:-22px; 
}
.marker-label:before {
    content: "";
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border: 2px solid #000;
    left: -6px;
    top: -6px;
    border-radius: 50%;
}
.marker-label:after {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -6px;
    bottom: -13px;
    width: 5px;
    height: 5px;
    border-style: solid;
    border-width: 8px 7px 0px 7px;
    border-color: #000 transparent transparent transparent;
    z-index: 1;
}
/* cribmap.css */
div.leaflet-container .leaflet-attribution-flag {
    display: none !important; /* Pour masquer l'attribution */
  }
  
  div.leaflet-touch .leaflet-control-attribution{
   display: none !important;
  }
.Cribes-container .Maps {
    position: relative;
}
/* .Cribes-container .Maps .maps-block.fixed {
    position: fixed;
    right: -40px;
    top: 20px;
    bottom: 20px;
    height: calc(100vh - 40px)!important;
    width: calc( 41.66666667% + 30px);
} */
.Cribes-container .Maps .maps-block.fixed .leaflet-container.leaflet-touch {
    height: calc(100vh - 40px)!important;
}

.carousel li {
    margin: 0;
    padding: 0;
}
.item-cribe .carousel .control-dots .dot {
    opacity: .5;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 3px;
}
 .item-cribe .carousel .control-dots .dot.selected, .item-cribe .carousel .control-dots .dot:hover {
    opacity: 1;
    background: #000;
}
.item-cribe .carousel .control-dots {
    margin: 6px 0;
}
.item-cribe .carousel .thumbs-wrapper {
    margin: 0;
}


/*****/
.Cribes-container .Maps {
    position: relative;
    display: block;
}
.Cribes-container .Maps .maps-block {
    position: sticky;
    right: -40px;
    top: 20px;

    width: calc(100% + 40px);
}
.row.row-cribes {
    position: relative;
    display: flex;
}
/*** popup ***/
.maps-block .leaflet-popup-content {
    margin: 0;
    padding: 0;
    width: 270px!important;
}
.maps-block .leaflet-popup {
    border-radius: 10px;
    border: none!important;
    padding: 0!important;
}
.maps-block .leaflet-container a.leaflet-popup-close-button {
    z-index: 2;
    background: #fff;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    border-radius: 0 10px 0 10px;
    line-height: 20px;
}
.maps-block .leaflet-popup .item-cribe .Item-badge .img-fluid {
    height: 130px!important;
    border-radius: 10px 10px 0 0;
    width: 100%;
}
.maps-block .leaflet-popup .item-cribe {
    margin: 0;
    width: 270px;
}
.maps-block .leaflet-popup .item-cribe .Rooms-content {
    padding: 12px 12px 17px;
}
.maps-block .leaflet-popup .Item-badge {
    border-radius: 10px 10px 0 0;
    margin-bottom: 0; 
}
.maps-block .leaflet-popup .item-cribe .Rooms-content p {
    margin: 0;
    color: #000;
}
.maps-block .leaflet-popup .item-cribe .Rooms-content .d-flex {
    margin: 0 0 8px!important;
}
.maps-block .leaflet-popup .item-cribe .Rooms-content .d-flex img {
    margin-right: 6px;
}
.maps-block .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 10px;
}
.maps-block .leaflet-popup .item-cribe .Rooms-content .pricecrib {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #000;

}
.maps-block .leaflet-popup .item-cribe .promo-image {
    right: 24px;
}
.map-error{
font-size: 16px;
font-weight: 600;
line-height: 20px;
text-align: left;

}
/******/
@media (max-width: 992px) {
.maps-block {
    position: relative;
    right: 40px;
    top: inherit;
    width: calc(100% - 80px);
}
.row-cribes {
    display: flex;
    flex-direction: column-reverse;
}
.Cribes-container .Maps .maps-block.fixed, .Cribes-container .Maps .maps-block {
    position: relative!important;
    right: inherit;
    top: inherit;
    bottom: inherit;
    width: 100%;
    height: 100%!important;
    margin: 0 0 20px;
}
.Cribes-container .Maps .maps-block.fixed .leaflet-container.leaflet-touch, .Cribes-container .Maps .maps-block .leaflet-container.leaflet-touch {
    height: 400px!important;
    z-index: 0;
}
}
@media (max-width: 768px) {
.Cribes-container .Maps .maps-block.fixed .leaflet-container.leaflet-touch, .Cribes-container .Maps .maps-block .leaflet-container.leaflet-touch {
    height: 450px!important;
    z-index: 0;
}
}

.No-rooms-container{
    padding: 80px 0;
}

.No-rooms-content{
    justify-content: space-between;
    margin-left: 0px;
}

.left {
    text-align: center;
    flex-direction: column;
    gap: 10px;
}

.left h3{
    font-size: 28px;
    font-weight: 400;
}

.left span {
    font-size: 16px;
    font-weight: 400;
}

.ImageNoRooms{
    width: 10%;
    margin: 0 auto;
}

.left button {
    font-size: 16px;
    font-weight: 600;
    padding: 9px;
    background-color: #35CE92;
    outline: none;
    border: none;
    width: 30%;
    border-radius: 8px;
    color: white;
    margin: 0 auto;
}