.special-demandes {
    padding: 25px;
    /* max-width: 600px; */
    margin: 0 auto;
    border: 1px solid #E7E7E7;
    border-radius: 12px;
  }
  .special-demandes h3{
    font-size: 30px;
    font-weight: 700;
    line-height: 27px;
  }
  .options {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    gap: 20px;
  }
  .option-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 214px;
    justify-content: center;
    gap: 15px;
  }
  
  .option {
    cursor: pointer;
    padding: 15px 20px 15px 20px;
    border-radius: 12px;
    text-align: center;
    margin-right: 10px;
    position: relative; 
    box-shadow: 0px 2px 11px 0px #00000024;
    min-width: 214px;
    min-height: 144px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

  }
  .option img{
    align-self: center;
    margin-top: 17px;
  }
  .options label{
    font-weight: 500;
    font-size: 19px;
  }
  
  .option:last-child {
    margin-right: 0;
  }
  
  .option.active {
    border: 2px solid #00BFFF;
   
  }
  
  .option .tick {
    position: absolute;
    top: 10%;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    display: block;
    padding: 1px 5px 3px 5px;
  }
  .option svg path{
    visibility: hidden;
  }
  .option.active .tick {

    background-color: #00BFFF;
  }
  .option.active svg path{
    visibility: visible;
  }
  .payment-button-container {
    margin-top: 30px;
    /* text-align: center; */
  }
  
  .payment-button {
    background-color: #00BFFF;
    color: white;
    padding: 12px 150px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-button:hover {
    background-color: #00BFFF;
  }
  
  .virement-info {
    margin-top: 20px;
    padding: 20px 0px;
    border-radius: 5px;
  }
  .virement-info h4{
    font-weight: 600;
    font-size: 21px !important;
    color: #1A1A1A;
    line-height: 30px !important;
  }
  
  .virement-table {
    width: 100%;
    border-collapse: separate;
    margin-top: 15px;
    border: 1px solid #E7E7E7;
    border-radius: 6px !important;
    padding: 10px;
  }
  
  .virement-table td {
    padding: 8px 0px;
    border-top: 1px solid #ddd;
    vertical-align: top;
  }
  
  .virement-table tr:first-child td {
    border-top: none;
  }
  
  .virement-table td:first-child {
    font-weight: 600;
    font-size: 16px;
    width: 42%;
  }
  @media(max-width: 1200px){
    .special-demandes h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
    }
    .options label {
      font-weight: 500;
      font-size: 18px;
    }
    .virement-info h4 {
      font-weight: 600;
      font-size: 20px !important;
      color: #1A1A1A;
      line-height: 30px !important;
    }
  }
  @media(max-width: 991px){
    .special-demandes h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
    }
    .option {
      min-width: 160px;
      min-height: 140px;
    }
    .option img {
      align-self: center;
      margin-top: 28px;
      width: 76px;
      height: 60px;
    }
    .options label {
      font-weight: 500;
      font-size: 16px;
    }
    .payment-button {
      background-color: #00BFFF;
        color: white;
        padding: 12px 110px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.3s ease;
     }
     .link-previous {
      display: inline-flex;
      align-items: center;
      text-decoration: underline;
      font-size: 16px;
      margin-bottom: 10px;

     }
     .special-demandes {
      padding: 18px;
      margin: 0 auto;
      border: 1px solid #E7E7E7;
       border-radius: 12px;
    }
    .virement-info h4 {
      font-weight: 600;
      font-size: 16px !important;
      color: #1A1A1A;
      line-height: 24px !important;
    }
  }
  @media(max-width: 767px){
    .btn-cartes {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .payment-button-container {
      margin-top: 0px;
    }
  }
  @media(max-width: 575px){
    .option {
      padding: 15px ;
    }
    .option svg{
      width: 16px;
      height: 14px;
    }
    .option .tick {
      padding: 0px 4px 0px 4px;
    }
  }
  @media(max-width: 430px){
    .option img {
      align-self: center;
      margin-top: 28px;
      width: 55px;
      height: 40px;
   }
   .option {
    min-width: 120px;
    min-height: 110px;
   }
   .payment-button {
    background-color: #00BFFF;
    color: white;
    padding: 12px 75px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
   }
  }