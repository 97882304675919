.Search-container{
    background-color: #F1F2F4;
    border-radius: 4px;
    padding: 30px 0;
}

.Search-container h2 {
    font-size: 32px;
    font-weight: 400;
    color: #000;
    margin: 0 0 16px;
}

.Form-city .form-control {
    width: 100%;
    padding: 8px 38px 8px 8px;
    border-radius: 6px;
    border: 1px solid #DDD;
    background: transparent;
    height: 40px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#keyword::placeholder{
    color: #7d7979;
}

label{
    display: block;
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 400px;
}

.Search-cribs-btn {
    padding: 10px 20px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    outline: none;
    border: none;
    color: white;
    background-color: black;
    border-radius: 6px;
    height: 40px;
}
.Search-cribs-btn:hover {
    background-color: #1c1c1c;
}
.item-cribe .Item-badge .img-fluid {
    border-radius: 6px;
    height: 200px!important;
    object-fit: cover;
}
.custom-select {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #fff;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 6px;
    border: 1px solid #DDD;
    background: #FFF;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
}
  
.slider-container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 95px;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1;
    padding: 15px 10px 10px;
    border-radius: 6px;
}
  
.slider-container h5{
    font-size: 16px;
    font-weight: 400;
}
.css-1hb7zxy-IndicatorsContainer {
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
}
.css-1fdsijx-ValueContainer {
    padding: 0!important;
}
.css-2ksxt2-control input {
    padding: 0!important;
}
/* .css-2ksxt2-control {
    border-radius: 6px;
    border: 1px solid #DDD;
    background: #FFF;
    height: 40px!important;
    padding: 8px 8px!important;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
} */
div.css-2ksxt2-control{
    -webkit-box-align: center;
    align-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all .1s ease 0s;
    padding: 1px 0px !important;
}


.select-wrapper {
    position: relative;
}
.input-select , .input-date {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
}
.input-select:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 9px;
    width: 13px;
    height: 8px;
    background-image: url("../../../assets/select-bottom.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    z-index: 0;
    pointer-events: none;
}
.input-date:after {
    z-index: 0;
}
.react-datepicker-wrapper {
    width: 100%;
}

.input-datepicker {
    position: relative;
    width: 100%;
}
.react-datepicker-popper{
    z-index:2;
}
.input-datepicker .form-control {
    position: relative;
    width: 100%!important;
    background: #fff;
}
.input-datepicker .form-control::placeholder, .input-datepicker .form-control::-moz-placeholder, .input-datepicker .form-control::-webkit-input-placeholder {
    color: #000;
} 
.form-control::placeholder {
    color: #000;
}
span.calendar-icon {
    position: absolute;
    right: 9px;
    top: 6px;
    z-index: 1;
    pointer-events: none;
    cursor: pointer;
}
.loop{
    width: 18px;
    height: auto;
}
.input-keyword{
    position: relative;
    background-color: #fff;
    border-radius: 6px;
}
.disabled {
    background-color:#F1F2F4;
  }
div .css-1dimb5e-singleValue{
    margin-top: -2px;
}
/* .rmdp-container {
    width: 100%;
} */
.Form-city {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.form-top{
    padding-left: 0.8rem;
}
@media (min-width: 1400px) {
    .Form-city.col-lg-3 {
        width: 15.7%;
    }
    .form-search-btn.col-lg-2 {
        width: 12%;
    }
}
@media  (max-width: 1400px) and (min-width: 1200px) {
    .Form-city.col-lg-3 {
        width: 24%;
    }
    .form-search-btn.col-lg-2 {
        width: 14.8%;
    }
}
@media (max-width: 1200px) and (min-width: 992px) {
    .Form-city.col-lg-3 {
    width: 24%;
}
}
@media (max-width: 992px) {
.Form-city {
    padding: 0 12px 16px !important;
    padding-right: 12px !important;
}
.form-top {
    padding-left: 0;
}
}
.clear-icon{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: baseline;
    color: lightslategray;
}
.price{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: baseline;
}

.rc-slider-track {
    height: 2px!important;
    background-color: #000000!important;
}
.rc-slider-rail {
    height: 2px!important;
    background-color: #DDDDDD!important;
}
.rc-slider-step { 
    height: 2px!important;
}
.rc-slider-handle {
    width: 12px!important;
    height: 12px!important;
    background: #fff!important;
    border: solid 2px #000!important;
    opacity: 1!important;
}
.rc-slider-handle:active, .rc-slider-handle:hover, .rc-slider-handle:focus-visible {
    border-color: #000!important;
    box-shadow: 0 0 5px #000!important;
}
.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px #000!important;
    background: #000!important;

}
button.close-price-slide {
    background: transparent!important;
    border: none!important;
    color: #A0A9B2;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: table;
    margin: 0 0 0 auto;
    outline: 0!important;
    box-shadow: none!important;
    position: absolute;
    right: 9px;
    top: 9px;
}
button.close-price-slide:hover {
    color: #000;
}
button.close-price-slide svg {
    width: 20px;
    height: 20px;
    font-size: 18px;
    top: -2px;
    position: relative;
}
span.price-range-input {
    display: flex!important;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

span.price-range-input label {
font-size: 16px;
font-weight: 400;
}

.close-icon{
    color: #A0A9B2;
    width:15px;
    height: 15px;
    cursor:pointer;

}
.vertical-line {
    border-left: 1px solid #ccc;
    height: 100%;
    margin-right: 10px;
    position: absolute;
    height: 77%;
    margin-right: 12px;
    right: 31px;
    margin: 0px 4px 0px 1px;
    top: 5px;
  }
  