.reservation-confirmation {
  text-align: center;
  padding-top: 60px;
}

.reservation-booking-details {
  max-width: 1008px;
  margin: 0 auto;
}

.reservation-confirmation h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;

}
.reservation-confirmation p{
  font-size: 18px;
  line-height: 31px;
  color: #000;
}

.booking-details-confirm p {
  font-size: 18px;
  line-height: 31px;
  color: #000;
  margin-bottom: 0;
}

.confirmation-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.confirmation-header .icon {
  font-size: 40px;
  color: green;
}


.reservation-details-confirm {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
}

.stay-details-confirm,
.booking-details-confirm {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 12px;
  text-align: left;
  flex: 1;

}

.booking-details-confirm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.stay-details-confirm h2 {
  font-size: 22px;
  font-weight: 400;
  color: #000;
  line-height: 27px;
}
.booking-details-confirm h4{
  font-size: 22px;
    font-weight: 700;
    color: #000;
    line-height: 27px;
}
.reservation-confirmation .stay-details-confirm p {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 27px;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  list-style: none;
  padding: 0;
}

.features-list li {
  display: flex;
  align-items: center;
}

.features-list .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.payment-summary {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #E7E7E7;
  flex-direction: column;
}

.payment-summary p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0;
}

.payment-summary .payment-method {
  padding: 12px;
}

.payment-summary span {
  font-size: 22px;
    line-height: 30px;
    font-weight: 700;
}

.payment-summary .amount {
  background: #00BFFF4D;
  padding: 12px;
}

.payment-summary .amount,
.payment-summary .payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-res-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.download-button {
  background-color: #2E8B57;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.support-info {
  margin-top: 20px;
  text-align: left;
}
.date-container-confirm {
  display: flex;
  align-items: center;
  gap: 30px;
}
.date-separator-confirm {
  width: 1px;
  height: 64px;
  background-color: #E7E7E7;
}
@media(max-width: 991px) {
  .date-container-confirm {
    display: flex;
    align-items: center;
    gap: 20px;
}
.features-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 0px;
  list-style: none;
  padding: 0;
}
}
@media(max-width: 767px) {
  .reservation-details-confirm {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 15px;
    flex-direction: column;
  }

  .booking-details-confirm {
    margin-bottom: 0px;
  }
  .booking-details-confirm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 18px;
  }
  .download-button {
    font-size: 18px;
  }
}
@media(max-width: 575px) {
  .reservation-confirmation h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 20px;
}
.reservation-confirmation p {
  font-size: 16px;
  line-height: 27px;
}
.stay-details-confirm h2 {
  font-size: 20px;
}
.reservation-confirmation .stay-details-confirm p {
  font-size: 20px;
}
.features-list li {
  display: flex
;
  align-items: center;
  font-size: 14px;
}
.booking-details-confirm h4 {
  font-size: 20px;
}
.payment-summary span {
  font-size: 16px;
}
.download-button {
  font-size: 16px;
}
}