.reservation-container {
    min-width: 400px;

}
.reservation-container h4{
    font-size: 22px;
    font-weight: 700;
    color: #000;
    line-height: 27px;
}
.reservation-container h3{
    font-size: 22px;
    font-weight: 400;
    color: #000;
    line-height: 27px;
}
.date-container {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 15px;
}

.date-item {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 22px;

}

.date-separator {
    width: 1px;
    height: 64px;
    background-color: #E7E7E7;
}

.stay-details,
.booking-details,
.summary {
    margin-bottom: 20px;
    border: 1px solid #E7E7E7;
    border-radius: 12px;
    padding: 20px;
}

.assistance {
    color: #2E8B57;
}
ul.amenities {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    font-size: 16px;
    color: #000000;
}

ul.amenities li {
    margin-bottom: 5px;
}

.total-amount {
    padding-top: 10px;
    margin-top: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00BFFF4D;
    padding: 12px;
}

.total-amount .price {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin: 5px 0;
}

.total-amount small {
    font-size: 15px;
    color: #000000;
}

.summary h4{
    border-bottom: 1px solid #000000;
    padding-bottom: 10px;
}
.summary p{
    color: #1A1A1A;
    font-size: 22px;
    font-weight: 700;
}
.booking-details p {
    color: #1A1A1A;
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 0;
}

@media(max-width: 1440px){
    .reservation-container {
        max-width: 400px;
        min-width: unset;
    }
}
@media(max-width: 1200px){
    .reservation-container {
        max-width: 400px;
        min-width: unset;
    }
    .reservation-container h3 {
        font-size: 20px;
        font-weight: 400;
        color: #000;
        line-height: 27px;
    }
    .reservation-container h4 {
        font-size: 20px;
        font-weight: 700;
        color: #000;
        line-height: 27px;
    }
    .booking-details p {
        color: #1A1A1A;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
    }
    .summary p {
        color: #1A1A1A;
        font-size: 20px;
        font-weight: 700;
    }
    .total-amount small {
        font-size: 14px;
        color: #000000;
    }
}

@media(max-width: 992px){
    .reservation-container h3 {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        line-height: 27px;
    }
    .reservation-container h4 {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        line-height: 20px;
    }
    ul.amenities {
        list-style: none;
        padding: 0;
        margin: 10px 0 0;
        font-size: 14px;
        color: #000000;
    }
    .stay-details, .booking-details, .summary {
        margin-bottom: 20px;
        border: 1px solid #E7E7E7;
        border-radius: 12px;
        padding: 15px;
    }
    .booking-details p {
        color: #1A1A1A;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 0px;
    }
    .date-separator {
        display: none;
    }
    .summary p {
        color: #1A1A1A;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
    }
    .total-amount .price {
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin: 5px 0;
    }
    .total-amount small {
        font-size: 14px;
        color: #000000;
        line-height: 20px;
    }
    .date-container {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        flex-direction: column;
        margin-bottom: 5px;

    }
   

}
@media(max-width: 767px){
    .reservation-container {
        max-width: unset;
        min-width: unset;
    }
}