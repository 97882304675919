.cancel-reservation-container {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .icon-container {
    font-size: 40px;
    margin-bottom: 20px;
  }
  

  
  .cancel-message {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
    line-height: 27px;
    color: black;
  }
  
  .retry-button {
    padding: 12px 50px;
    background-color: #2E8B57;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    border: 2px solid #2E8B57 !important;
  }
  
  .retry-button:hover {
    background-color: #fff;
    color: #2E8B57;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    border: 1px;
    border-color: #2E8B57;
  }
  @media (max-width: 768px) {
    .cancel-message {
        font-size: 22px;
        margin-bottom: 50px;
        font-weight: 700;
        line-height: 27px;
        color: black;
    }
    .retry-button {
        font-size: 16px;
    }
  }