/* .user-details-form {
    max-width: 1090px;
    margin: 20px auto;
    padding: 20px;
} */

.login-reminder p {
    padding: 20px 25px;
    border-radius: 12px;
    margin-bottom: 20px;
    font-size: 20px;
    border: 1px solid #E7E7E7;
    display: flex;
    gap: 20px;
    align-items: center;
    line-height: 35px;
}

.form-section ,
.special-section ,
.arrival-time {
    margin-bottom: 20px;
    padding: 25px;
    border-radius: 12px;
    border: 1px solid #E7E7E7;
}

.user-details-form h3 {
    font-size: 24px;
    color: #1A1A1A;
    margin-bottom: 15px;
    font-weight: 700;
}
.special-section textarea{
    border: 1px solid #595959;
    padding: 30px;
    border-radius: 7px 7px 12px 12px;
}
.user-details-form h4 {
    font-size: 18px;
    color: #1A1A1A;
    margin-bottom: 15px;
    font-weight: 700;
}
.special-section p{
    font-size: 18px;
    line-height: 33px;
}

.form-section p {
    font-size: 18px;
    margin-bottom: 25px;
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.form-group input,
.form-group select {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #868686;
    border-radius: 6px;
}
.email-group{
    grid-column: span 2;
    width: 49%;
}
.check-group{
    grid-column: span 2;
}

textarea {
    resize: none;
}
.input-time{
    border: 1px solid #595959;
    border-radius: 6px;
    padding: 10px;
}

.form-section ul {
    list-style: none;
    padding-left: 0px;
    font-size: 14px;
    color: #555;
    margin-bottom: 0 !important;
}

.form-section ul li {
    padding-left: 0; 
    color:#1A1A1A;
    ;
    font-size: 18px;
}
.form-section ul li::before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 28px;
    background-image: url('../../../assets/booking/savoir.svg');
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle; 
    margin-right: 10px; 
}

.required {
    color: red;
    font-weight: bold;
}

.login-reminder a{
    color: #2E8B57 !important;
    text-decoration: underline !important;
    text-underline-offset: 3px;
}
.css-1xc3v61-indicatorContainer {
    position: relative;
    top: 14%;
}
.css-151sz6c-indicatorContainer {
    position: relative !important;
    top: 14% !important;
}
.css-13cymwt-control {
    padding: 7px;
    border: 1px solid #868686 !important;
}
.css-1u9des2-indicatorSeparator {
   display: none !important;
}
.css-t3ipsp-control {
    padding: 7px;
    border: 1px solid #868686 !important;
}
.input-time {
    -webkit-appearance: none; /* Chrome, Safari */
  -moz-appearance: textfield; /* Firefox */
  appearance: none; /* Standard */
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.input-time::-webkit-clear-button, 
.input-time::-webkit-outer-spin-button, 
.input-time::-webkit-inner-spin-button {
    display: none;  
}
input.custom-checkbox {
    width: 20px !important;
    height: 20px;
    margin-top: 5px;
}


.custom-time-picker:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
}
.react-time-picker__inputGroup__input {
    width: 70px !important;
    padding: 4px;
    text-align: center; 
    font-size: 16px;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour {
    font-size: 17px;
    padding: 9px;
}
input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
    font-size: 17px;
    padding: 9px;
}
.react-time-picker__inputGroup__leadingZero {
    display: none; 
}
  .custom-time-picker::placeholder {
    color: #888;
  }
  input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour.react-time-picker__inputGroup__input--hasLeadingZero{
   width: 100% !important;
}  
span.react-time-picker__inputGroup__divider{
    margin: 0 20px;
}
.arrival-time p{
    font-size: 18px;
    line-height: 33px;
}
.form-grid input,
.form-grid label {
  width: 100%;
  box-sizing: border-box;
}
.css-13cymwt-control {
    border-radius: 6px;
}
.error{
    color: #FF4B55;
    font-size: 14px;
}
@media(max-width: 1200px){
    .login-reminder p {
        padding: 20px 25px;
        border-radius: 12px;
        margin-bottom: 20px;
        font-size: 18px;
        border: 1px solid #E7E7E7;
        display: flex;
        gap: 20px;
        align-items: center;
        line-height: 29px;
    }
    .user-details-form h3 {
        font-size: 22px;
        color: #1A1A1A;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .form-section p {
        font-size: 16px;
        margin-bottom: 25px;
        background-color: #F5F5F5;
        padding: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .form-group label {
        margin-bottom: 5px;
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
    }
    .special-section p {
        font-size: 16px;
        line-height: 29px;
    }
    .user-details-form h4 {
        font-size: 18px;
        color: #1A1A1A;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .form-section ul li {
        padding-left: 0;
        color: #1A1A1A;
        font-size: 16px;
    }
    .arrival-time p {
        font-size: 16px;
        line-height: 29px;
    }
}
@media(max-width: 991px){
    .step-line {
        position: absolute;
        top: 50%;
        left: calc(50% + 62px);
        right: 0;
        height: 2px;
        background-color: #00BFFF;
        z-index: 1;
        transform: translateY(-50%);
    }
    .step-title {
        margin-left: 10px;
        font-size: 16px;
        color: #000;
        font-weight: 600;
    }
    input.custom-checkbox {
        width: 20px !important;
        height: 20px;
        margin-top: 0px;
    }
}
@media(max-width: 992px){
    .login-reminder p {
        padding: 18px 18px;
        border-radius: 12px;
        margin-bottom: 20px;
        font-size: 16px;
        border: 1px solid #E7E7E7;
        display: flex;
        gap: 20px;
        align-items: center;
        line-height: 24px;
    }
    .login-reminder img {
        width: 30px;
        height: 35px;
    }
    .form-section, .special-section, .arrival-time {
        margin-bottom: 20px;
        padding: 18px;
        border-radius: 12px;
        border: 1px solid #E7E7E7;
    }
    .user-details-form h3 {
        font-size: 16px;
        color: #1A1A1A;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .form-section p {
        font-size: 15px;
        margin-bottom: 25px;
        background-color: #F5F5F5;
        padding: 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 22px;
    }
    .form-group label {
        margin-bottom: 5px;
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
        line-height: 21px;
    }
    .form-group input, .form-group select {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #868686;
        border-radius: 6px;
    }
    .special-section p {
        font-size: 15px;
        line-height: 24px;
    }
    .user-details-form h4 {
        font-size: 16px;
        color: #1A1A1A;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .arrival-time p {
        font-size: 15px;
        line-height: 24px;
    }
    .email-group {
        grid-column: span 2;
        width: 100%;
    }
}
@media (max-width: 767px){
    .step-title {
        margin-left: 10px;
        font-size: 14px;
        color: #000;
        font-weight: 600;
    }
    .form-grid {
        display: flex;

        gap: 20px;
        flex-direction: column;
    }
    .next-button .btn {
        padding: 10px 35px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 50%;
    }
    .email-group {
        grid-column: span 2;
        width: 100%;
    }
}
@media (max-width: 575px){
    .next-button .btn {
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 40%;
        font-size: 15px;
    }
}