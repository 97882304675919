.blog-section h2{
font-size: 32px;
    font-weight: 400;}

.blog-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 2rem !important; */
    gap: 20px;
}
.blog-section{
    margin-top: 70px;
}
.blog-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.3s ease-in-out;
    flex: 0 1 calc(33.333% - 1rem); 
    margin-bottom: 5rem;
    cursor: pointer;
}

.blog-card:hover {
    transform: translateY(-5px);
}
img.blog-image{
    border-radius: 10px;
}
.blog-content {
    position: absolute;
    bottom: 0;
    background: #ffffff;
    width: 90%;
    border-radius: 6px;
    padding: 1rem;
    text-align: left;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2rem;
    box-shadow: 0px 3px 6px 0px #00000029;
}
.blog-content h5{
    font-weight: 600 !important;
    font-size: 18px !important;
}
.blog-description{
    font-size: 16px;
}
.visit-blog-button {
    background-color: #2E8B57;
    color: white;
    border: none;
    padding: 0.75rem 3rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex; 
    gap: 12px;
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
    margin-bottom: 64px !important;
}
.visit-blog-button:hover{
    box-shadow: inset 0px 2px 8px 2px rgba(0, 0, 0, .3) !important; 
    background-color: #2E8B57 !important;
}
.blog-date{
    display: flex;
    gap: 10px;
}
.no-blog{
    font-size: 18px;
    margin-bottom: 0 !important;
}
@media(max-width: 1024px) {
    .blog-cards-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
    
    }
 .blog-card {
    flex: 0 1 48%;
    }

}

@media(max-width: 991px){
    .blog-section {
        margin-top: 48px;
    }
}

@media(max-width: 768px) {
    /* .blog-card {
        flex: 0 1 100%; 
    } */
    .blog-content {
        position: absolute;
        bottom: 0;
        background: #ffffff;
        width: 94%;
        border-radius: 6px;
        padding: 1rem;
        text-align: left;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5rem;
        box-shadow: 0px 3px 6px 0px #00000029;
    }
    .visit-blog-button {
       
        margin-bottom: 45px !important;
    }
}

@media(max-width: 1399px) and (min-width: 1024px) {

    .blog-cards-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 4rem;
}
.blog-card {
    margin-bottom: 1.5rem;
}
.blog-content{
    min-height: 232px;
}

} 

@media(max-width: 769px) {
    .blog-cards-container {
            display: flex;
            align-items: start;
          
            margin-bottom: 58px;
        
    }
    .blog-card {
        margin-bottom: 5rem;
    }
  
}

@media (max-width: 992px) {
    .blog-section h2 {
        font-size: 28px;
    }
    .blog-content h5{
        font-weight: 600 !important;
        font-size: 16px !important;
    }
    .blog-description{
        font-size: 14px;
    }
}
@media (max-width: 600px) {
    .blog-section h2 {
        font-size: 24px;
        font-weight: 400;
    }
}
@media (max-width: 575px) {
    .blog-section h2 {
        font-size: 22px;
        font-weight: 400;
        line-height: 1.4;
    }
    .blog-content {
        position: absolute;
        bottom: 0;
        background: #ffffff;
        width: 96%;
        border-radius: 6px;
        padding: 1rem;
        text-align: left;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4rem;
        box-shadow: 0px 3px 6px 0px #00000029;
    }
}
@media(max-width: 575px){
    .blog-card {
        flex: 0 1 100%;
        margin-bottom: 5rem;
    }
    img.blog-image {
        border-radius: 10px;
        width: 100%;
    }
    .blog-cards-container {
        display: flex;
        align-items: start;
        margin-bottom: 34px;
    }
    .blog-section {
        margin-top: 35px;
    }
    .visit-blog-button {
        padding: 0.75rem 1.5rem;
    }
}
@media (max-width: 480px) {
    .blog-cards-container {
        display: flex;
        align-items: start;
        flex-wrap: nowrap;
        margin-bottom: 38px;
        flex-direction: column;
    }
    .blog-card {
        flex: 0 1 100%;
        margin-bottom: 4rem;
    }
    .blog-content {
        position: absolute;
        bottom: 0;
        background: #ffffff;
        width: 92%;
        border-radius: 6px;
        padding: 1rem;
        text-align: left;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4rem;
        box-shadow: 0px 3px 6px 0px #00000029;
    }
}