.language-switcher {
    position: relative;
    display: inline-block;
    margin-left: 20px;
    padding: 8px 10px 7px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
  }
  
  .language-btn {
    background: none;
    border: none;
    cursor: pointer !important;
    display: flex;
    align-items: center;
  }
  
  .language-btn img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .language-dropdown {
    position: absolute;
    top: 112%;
    left: 0;
    background: white;
    z-index: 1000;
    list-style: none;
    padding: 0;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
  }
  
  .language-dropdown li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .language-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  .language-dropdown li img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  @media (max-width: 991px) {
    .language-switcher {
      position: relative;
      display: inline-block;
      margin-left: 16px;
      padding: 8px 8px 7px;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #DDDDDD;
      border-radius: 6px;
  }
  .language-btn img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    vertical-align: middle;
}
.language-dropdown li img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
.language-dropdown {
  position: absolute;
  top: 112%;
  left: 0;
  background: white;
  z-index: 1000;
  list-style: none;
  padding: 0px;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
}
.language-dropdown li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
  }