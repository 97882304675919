.error-message {
    font-size: 13px;
    font-weight: 400;
    color: red;
}



.contact-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 30px;
    color: #000;
}

.Contact-container form {

    padding: 32px 15px 32px 15px;
    background-color: white;
    border-radius: 6px;

    margin-right: 0;

}

.Contact-container form h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #000;
}

.Contact-container form span {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.Contact-container .form-control {
    border: 1px solid #DFDFDF !important;
    background: #FAFAFA !important;
}
.Contact-container .flag-dropdown{
    background: #FAFAFA !important;
}
.Contact-container form label {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}

.react-tel-input .form-control {
    width: 100%;
}

.react-tel-input .country-list .country-name,
.react-tel-input .country-list .country .dial-code {
    font-size: 16px;
}

.Contact-container form .btn-primary {
    margin-top: 0px;
    margin-bottom: 30px;
    width: 35%;
    border-radius: 6px;
    background-color: #2E8B57 !important;
    border: none;
}

.Contact-container form .btn-primary:hover {
    box-shadow: inset 0px 2px 8px 2px rgba(0, 0, 0, .3) !important;
    background-color: #2E8B57 !important;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.form-outline {
    margin: 0 0 22px;
}

textarea.form-control {
    height: 71px !important;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.custom-button {
    width: 35% !important;

}

.breadcrumbs {
    margin-top: 20px !important;
    margin-bottom: 18px !important;
}

@media (max-width: 1200px) and (min-width : 768px) {
    .custom-button {
        width: 70% !important;
    }
}

@media (max-width: 767px) {
    .custom-button {
        width: 100% !important;
    }
}

@media (max-width: 567px) {
    .form-outline {
        margin: 0 0 16px;
    }

    .Contact-container form label {
        font-size: 16px;
    }
}