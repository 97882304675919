.FaqpagesContainer{
    cursor: pointer;
}
.faq-tabs h4 {
    font-size: 18px;
    font-weight: 700;
}
.faq-tabs a {
    color: #000;
    text-decoration: underline;
}
.faq-tabs a:hover , .faq-tabs a:focus {
    text-decoration: none;
}
.faq-tabs p, .faq-tabs ul li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
}
.bloc-tabs>div,
.TitlesRenting h2
 {
    font-size: 18px;
    font-weight: 400;
    color: #000;
}
.subTitlesRentingActive .subtitle,
.subTitlesRenting .subtitle
{
    font-size: 16px;
    font-weight: 400;
    padding-left: 48px;
}

.active-tabs{
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    color: #000!important;
}

.tabs{
    color: #A0A9B2!important;
    border-bottom: 1px solid #A0A9B2;
}


.content {
    display: none;
}

.subAnswers{
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    margin: 0!important;
}

.collapseRenting
 {
    position: relative;
    border: 1px solid #00BFFF;
    border-radius: 6px;
    background: #ffffff;
    overflow: hidden;
}

.col-content.active {
    display: block!important;
}
.col-content {
    position: relative;
    /* right: -48px;
    top: 0 ; */
    height: 100%;
    background-color: #F5F5F5;
    padding: 25px 25px 25px 35px;
}

.icon {
    fill: #DDDDDD;
  }

/* .TitlesRenting {
    
} */
.faq-tabs {
    max-width: 860px;
    width: 100%;
    display: table;
    margin: 0 auto 140px;
}
.arrow{
    color: #000;
    font-size: 20px;
}
.fleche{
    display:none;

}
.subTitlesRentingActive .subtitle {
    background-color: #F5F5F5;
}
/*** tabs ***/
.faq_tabspills .tab-content {
    width: 58%!important;
    background-color: #DBF6FF;
}
.faq_tabspills .nav-pills {
    width: 42%!important;
    background: #ffffff; 
}
.faq_tabspills .nav-pills,
.faq_tabspills .tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1!important;
  flex-shrink: 1!important;
  flex-flow: 1!important;
  flex-basis: auto!important;
}
.faq_tabspills .nav-pills .nav-link {
    color: #000!important;
    background: #fff!important;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 14px 12px 49px;
    text-align: left;
    border-radius: 0!important;
    outline: 0!important;
    box-shadow: none!important;
}
.faq_tabspills .nav-pills .nav-link.active {
    background: #DBF6FF !important;
    color: #000!important;
}
.faq_tabspills .tab-content .tab-pane {
    padding: 14px 30px 25px 35px;
}
.faq_tabspills .tab-content .tab-pane.active {
    background: #DBF6FF !important;
}
/**** video popup ****/
button.link-video {
    padding: 0!important;
    border: none!important;
    background: transparent!important;
    color: #000!important;
    margin: 0!important;
    outline: 0!important;
    box-shadow: none!important;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline;
}
button.link-video:hover {
    text-decoration: none;
}
.reactmodal-portal-modal-video.ReactModalPortal .ReactModal__Content.ReactModal__Content--after-open {
    background: rgba(0,0,0,0)!important;
}
.reactmodal-portal-modal-video.ReactModalPortal .ReactModal__Overlay {
    /* background-color: transparent!important; */
    background: rgba(0,0,0,0.7)!important;
}
.modal-video-content {
    max-width: 800px!important;
    width: 100%;
    display: table;
    margin: 1rem auto;
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
}
.modal-video-text iframe {
    width: 100%;
    border-radius: 6px;
    height: 440px;
}
.modal-video-overlay {
    padding: 1.5rem;
}
.modal-video-content .modal-video-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 16px;
}
.modal-video-content .modal-video-head .h2 {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
}
.modal-video-content .btn-close {
    background: transparent!important;
    padding: 0;
    width: auto;
    height: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #000;
    opacity: 1;
    outline: 0!important;
    border: none!important;
    box-shadow: none!important;
    margin: 0 0 0 auto;
}
.modal-video-content .btn-close svg {
    width: 34px;
    height: 30px;
    margin-top: -2px;
}
/**** responsive ***/
@media (max-width: 991px) {

  
    .col-content {
        position: relative;
        right: -48px;
        top: 0 ;
        height: 100%;
        background-color: #F5F5F5;
        /* max-width: 80%;
        width: 60%; */
        padding: 10px;
    }
    .subTitlesRentingActive {
       margin-right: -48px;
     }
    .fleche{
        display:inline-block  !important;
        font-size: 24px;
        font-weight: bold;
        color: black;
    }
    .subTitlesRentingActive h2,
    .subTitlesRenting h2{
        font-weight: bold;
    }
    .modal-video-text iframe {
        height: 400px;
    }
  }

@media (max-width: 768px) {
    .bloc-tabs {
        display: flex;
        flex-direction: row;
       
        align-items: center;
    }
    
    .bloc-tabs > div {
        flex: 0 0 50%;
        width: 48%; /* Ajustez la largeur des colonnes selon vos besoins */
        margin: 0;
       
    }
    .faq_tabspills {
        flex-wrap: wrap;
    }
    .faq_tabspills .nav-pills {
        width: 100%!important;
    }
    .faq_tabspills .tab-content {
        width: 100%!important; 
    }
    
    .modal-video-text iframe {
        height: 300px;
    }
    .faq-tabs {
        margin: 0 auto 80px;
    }
}
@media (max-width: 576px) {
    .faq_tabspills .nav-pills .nav-link {
    padding: 10px 10px 10px 16px; 
}
.faq_tabspills .tab-content .tab-pane {
    padding: 12px 12px 16px 16px;
}
.modal-video-text iframe {
    height: 300px;
}
.modal-video-overlay {
    padding: 0 1rem;
}
.bloc-tabs > div {
    flex: 0 0 100%;
    width: 100%;
}
.faq-tabs {
    margin: 0 auto 70px;
}
}