.blog-full-content{
    box-shadow: 0px 3px 6px 0px #00000029;
    font-size: 16px;
    font-weight: 400;
}
.blog-content-section h2{
     font-size: 28px;
     font-weight: 600;
}

.blog-content-section {
    text-align: center;
}

.image-text-container {
    display: inline-block;
    width: auto; 
    text-align: left; 
    box-shadow: 0px 3px 6px 0px #00000029;
    font-size: 16px;
    font-weight: 400;
}

.blog-content-image {
    width: 100%; 
    max-height: 513px;
    display: block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.blog-full-content-text {
    width: 100%; 
    margin-top: 10px; 
    padding: 30px;
}
@media(max-width: 575px){
    .blog-content-section h2 {
        font-size: 22px;
        font-weight: 600;
    }
    .blog-full-content-text {
        padding: 15px;
    }
}
